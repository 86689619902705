import { ExclamationCircleIcon } from '@heroicons/react/20/solid'
import {
	Fragment,
	useEffect,
	useRef,
	useState,
	type ChangeEvent,
	type KeyboardEvent,
} from 'react'
import { getTrackBackground, Range } from 'react-range'
import TextareaAutosize, {
	type TextareaAutosizeProps,
} from 'react-textarea-autosize'
import { type SelectItem } from '#app/utils/select-item'
import { cx } from '#app/utils/tailwind'

type CheckboxGroupLabelErrorType = JSX.IntrinsicElements['input'] & {
	defaultCheckedItems: SelectItem['id'][]
	// TODO SF220630 when zod is removed, can remove string[]
	error?: string | string[] | null
	items: SelectItem[]
	label: string
}

export function CheckboxGroupLabelError({
	defaultCheckedItems,
	error,
	items,
	label,
	// input props
	name,
	...props
}: CheckboxGroupLabelErrorType) {
	return (
		<div>
			<label className="text-base font-medium text-gray-700">{label}</label>
			<fieldset className="mt-1">
				<div
					className={cx(
						'rounded-md border p-2 sm:flex sm:flex-wrap sm:items-center sm:gap-x-6 sm:gap-y-2',
						error
							? 'border-red-300 bg-yellow-200 text-red-900 focus:border-red-500 focus:outline-none focus:ring-red-500'
							: 'border-gray-200 bg-gray-50',
					)}
				>
					{items.map((x) => (
						<div key={x.id} className="flex items-center">
							<input
								id={`${name}-${x.id}`}
								name={name}
								type="checkbox"
								defaultChecked={defaultCheckedItems.includes(x.id)}
								value={x.id}
								className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-500"
								{...props}
							/>
							<label
								htmlFor={`${name}-${x.id}`}
								className="ml-3 block text-base font-medium"
							>
								{x.name}
							</label>
						</div>
					))}
				</div>
			</fieldset>
			{error ? <p className="mt-2 text-red-600">{error}</p> : null}
		</div>
	)
}

// d yyyy-mm-dd
function decodeDateInput(d: string): string[] {
	if (d.length < 8) return []

	let day = d.substring(8, 10)
	let month = d.substring(5, 7)
	let year = d.substring(0, 4)

	return `${day}${month}${year}`.split('')
}

function encodeDateInput(sx: string[]): string {
	let s = sx.join('')
	let day = s.substring(0, 2)
	let month = s.substring(2, 4)
	let year = s.substring(4, 8)
	return `${year}-${month}-${day}`
}

type DateInputLabelErrorProps = JSX.IntrinsicElements['input'] & {
	// TODO SF220630 when zod is removed, can remove string[]
	error?: string | string[] | null
	label: string
}

// https://www.youtube.com/watch?v=1Mz07nptjWU
// https://www.youtube.com/watch?v=UbBdShqXhN8
export function DateInputLabelError({
	defaultValue,
	error,
	label,
	...props
}: DateInputLabelErrorProps) {
	let [dateArray, setDateArray] = useState<string[]>(
		decodeDateInput(String(defaultValue)),
	)
	let [activeIndex, setActiveIndex] = useState(0)

	let inputRef = useRef<HTMLInputElement>(null)

	let handleOnChange = (
		{ target }: ChangeEvent<HTMLInputElement>,
		index: number,
	): void => {
		let { value } = target
		let newOtp = [...dateArray]
		newOtp[index] = value.substring(value.length - 1)

		if (!value) {
			setActiveIndex(index - 1)
		} else {
			setActiveIndex(index + 1)
		}

		setDateArray(newOtp)
	}

	let handleOnKeyDown = (
		{ key }: KeyboardEvent<HTMLInputElement>,
		index: number,
	) => {
		if (key === dateArray[index]) {
			if (!inputRef.current) return
			// prefix with space to force onChange event
			inputRef.current.value = ` ${key}`
		}
	}

	useEffect(() => {
		if (!inputRef.current) return
		inputRef.current.focus()
		inputRef.current.select()
	}, [activeIndex])

	return (
		<div>
			<label htmlFor={props.id} className="block font-medium text-gray-700">
				{label}
			</label>
			<div className="mt-1 flex items-center gap-x-1.5 sm:gap-x-2">
				<input type="hidden" {...props} value={encodeDateInput(dateArray)} />
				{dateArray.map((_, index) => {
					return (
						<Fragment key={index}>
							<input
								ref={index === activeIndex ? inputRef : null}
								type="number"
								onChange={(e) => handleOnChange(e, index)}
								onKeyDown={(e) => handleOnKeyDown(e, index)}
								placeholder={index <= 1 ? 'D' : index <= 3 ? 'M' : 'Y'}
								value={dateArray[index]}
								className={cx(
									'w-9 text-center sm:w-12',
									error &&
										'border-red-300 bg-yellow-200 font-semibold text-red-900 placeholder-red-300 focus:border-red-500 focus:ring-red-500',
								)}
							/>
							{index === 1 || index === 3 ? (
								<span className="w-2 text-xl font-semibold text-gray-400">
									/
								</span>
							) : null}
						</Fragment>
					)
				})}
			</div>
			{error ? (
				<p id={`${props.id}-error`} className="mt-2 text-red-600">
					{error}
				</p>
			) : null}
		</div>
	)
}

type InputLabelProps = JSX.IntrinsicElements['input'] & {
	label: string
}

export function InputLabel({ label, ...props }: InputLabelProps) {
	return (
		<div>
			<label htmlFor={props.id} className="block font-medium text-gray-700">
				{label}
			</label>
			<div className="mt-1">
				<input
					{...props}
					className={cx('read-only:bg-slate-100', props.className)}
				/>
			</div>
		</div>
	)
}

type InputLabelErrorProps = JSX.IntrinsicElements['input'] & {
	// TODO SF220630 when zod is removed, can remove string[]
	error?: string | string[] | null
	label: string
}

export function InputLabelError({
	error,
	label,
	...props
}: InputLabelErrorProps) {
	return error ? (
		<div>
			<label htmlFor={props.id} className="block font-medium text-gray-700">
				{label}
			</label>
			<div className="relative mt-1 rounded-md shadow-sm">
				<input
					{...props}
					className={cx(
						'block w-full rounded-md border-red-300 bg-yellow-200 pr-10 font-semibold text-red-900 placeholder-red-300 focus:border-red-500 focus:outline-none focus:ring-red-500',
						props.className,
					)}
					aria-invalid="true"
					aria-describedby={`${props.id}-error`}
				/>
				<div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
					<ExclamationCircleIcon
						className="h-5 w-5 text-red-500"
						aria-hidden="true"
					/>
				</div>
			</div>
			<p id={`${props.id}-error`} className="mt-2 text-red-600">
				{error}
			</p>
		</div>
	) : (
		<InputLabel label={label} {...props} />
	)
}

type SelectLabelErrorProps = JSX.IntrinsicElements['select'] & {
	// TODO SF220630 when zod is removed, can remove string[]
	error?: string | string[] | null
	items: SelectItem[]
	label: string
}

export function SelectLabelError({
	error,
	items,
	label,
	...props
}: SelectLabelErrorProps) {
	return (
		<div>
			<label htmlFor={props.id} className="block font-medium text-gray-700">
				{label}
			</label>
			<select
				{...props}
				className={cx(
					'mt-1 py-2 pl-3 pr-10 text-base focus:outline-none',
					error &&
						'border-red-300 bg-yellow-200 text-red-900 focus:border-red-500 focus:outline-none focus:ring-red-500',
				)}
			>
				{items.map((item) =>
					item.id === '-' ? (
						<option key={item.id} disabled>
							──────────
						</option>
					) : (
						<option key={item.id} value={item.id}>
							{item.name}
						</option>
					),
				)}
			</select>
			{error ? <p className="mt-2 text-red-600">{error}</p> : null}
		</div>
	)
}

type TextareaLabelProps = JSX.IntrinsicElements['textarea'] & {
	label: string
}

export function TextareaLabel({ label, ...props }: TextareaLabelProps) {
	return (
		<div>
			<label htmlFor={props.id} className="block font-medium text-gray-700">
				{label}
			</label>
			<div className="mt-1">
				<textarea {...props} className={props.className} />
			</div>
		</div>
	)
}

type TextareaLabelErrorProps = JSX.IntrinsicElements['textarea'] & {
	error?: string | string[] | null
	label: string
}

export function TextareaLabelError({
	error,
	label,
	...props
}: TextareaLabelErrorProps) {
	return error ? (
		<div>
			<label htmlFor={props.id} className="block font-medium text-gray-700">
				{label}
			</label>
			<div className="relative mt-1 rounded-md shadow-sm">
				<textarea
					{...props}
					className={cx(
						'block w-full rounded-md border-red-300 bg-yellow-200 pr-10 font-semibold text-red-900 placeholder-red-300 focus:border-red-500 focus:outline-none focus:ring-red-500',
						props.className,
					)}
					aria-invalid="true"
					aria-describedby={`${props.id}-error`}
				/>
				<div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
					<ExclamationCircleIcon
						className="h-5 w-5 text-red-500"
						aria-hidden="true"
					/>
				</div>
			</div>
			<p id={`${props.id}-error`} className="mt-2 text-red-600">
				{error}
			</p>
		</div>
	) : (
		<TextareaLabel label={label} {...props} />
	)
}

type ExpandingTextareaLabelErrorProps = TextareaAutosizeProps & {
	error?: string | null
	label: string
}

export function ExpandingTextareaLabelError({
	error,
	label,
	...props
}: ExpandingTextareaLabelErrorProps) {
	return (
		<div>
			<label htmlFor={props.id} className="block font-medium text-gray-700">
				{label}
			</label>
			<div className="relative mt-1 rounded-md shadow-sm">
				<TextareaAutosize
					{...props}
					className={cx(
						error &&
							'block w-full rounded-md border-red-300 bg-yellow-200 pr-10 font-semibold text-red-900 placeholder-red-300 focus:border-red-500 focus:outline-none focus:ring-red-500',
						props.className,
					)}
					aria-invalid="true"
					aria-describedby={`${props.id}-error`}
				/>
				{error ? (
					<div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
						<ExclamationCircleIcon
							className="h-5 w-5 text-red-500"
							aria-hidden="true"
						/>
					</div>
				) : null}
			</div>
			{error ? (
				<p id={`${props.id}-error`} className="mt-2 text-red-600">
					{error}
				</p>
			) : null}
		</div>
	)
}

type TwoRangeInputLabelProps = JSX.IntrinsicElements['input'] & {
	label: string
	max: number
	maxName: string
	maxValue: number
	min: number
	minName: string
	minValue: number
	step: number
}

export function TwoRangeInput({
	label,
	max,
	maxName,
	maxValue,
	min,
	minName,
	minValue,
	step,
	...props
}: TwoRangeInputLabelProps) {
	let [values, setValues] = useState([minValue, maxValue])

	return (
		<div>
			<label htmlFor={props.id} className="flex justify-between font-medium">
				<span className="text-gray-700">{label}</span>
				<span className="ml-2 text-gray-900">{`${values[0]}-${values[1]}`}</span>
			</label>

			<input type="hidden" name={minName} value={values[0]} />
			<input type="hidden" name={maxName} value={values[1]} />

			<Range
				values={values}
				step={step}
				min={min}
				max={max}
				onChange={(values) => {
					setValues(values)
				}}
				renderTrack={({ props, children }) => (
					<div
						onMouseDown={props.onMouseDown}
						onTouchStart={props.onTouchStart}
						style={{
							...props.style,
							height: '36px',
							display: 'flex',
							width: '100%',
						}}
						className="px-2.5"
					>
						<div
							ref={props.ref}
							style={{
								height: '5px',
								width: '100%',
								borderRadius: '4px',
								background: getTrackBackground({
									values,
									colors: ['#ccc', '#548BF4', '#ccc'],
									min,
									max,
								}),
								alignSelf: 'center',
							}}
							className="my-4 h-3 w-full rounded-md bg-gray-200 pr-2"
						>
							{children}
						</div>
					</div>
				)}
				renderThumb={({ props }) => (
					<div
						{...props}
						className="h-6 w-6 translate-x-10 transform rounded-full bg-indigo-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
					></div>
				)}
			/>
		</div>
	)
}
